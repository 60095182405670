import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, throwError, of  } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserInfo } from '../models/user-info.model';
import { v4 as uuidv4 } from 'uuid';
import { UserLocal } from '../models/user-local.model';
import { catchError, switchMap } from 'rxjs/operators';
import { CognitoService } from '../services/cognito.service';
import { UserService } from '../services/user.service';
import { Cart } from '../models/cart.model';
import { Client } from '../models/client.model';
import { ApiService } from '../services/api.service';
import { VisitPlanService } from '../services/visit-plan.service';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import { environment } from 'src/environments/environment';
import { ModalsService } from '../services/modals.service';
import { ExternalIntegration } from 'src/app/pages/external-integration/enums/external-integration.enum';
import packageJson from 'package.json';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class AwsInterceptor implements HttpInterceptor, OnDestroy {
  private readonly ExternalIntegration = ExternalIntegration;
  private subscriptions = new Subscription();
  cart: Cart;
  user: UserInfo;
  userLocal: UserLocal;
  client: Client;

  constructor(
    private store: Store<{ user: UserInfo, userLocal: UserLocal, cart: Cart, client: Client }>,
    private cognitoService: CognitoService,
    private userService: UserService,
    private apiService: ApiService,
    private visitPlanService: VisitPlanService,
    private modalService: ModalsService,
    private spinnerService: SpinnerService
  ) {
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.user = user)));
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.client = client)));
    this.subscriptions.add(this.store.select('userLocal').subscribe((userLocal) => (this.userLocal = userLocal)));
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isAssetRequest(req)) { return next.handle(req) }
    return this.handleTokenExpiration().pipe(
      catchError((err) => throwError(err)),
      switchMap(() => {
        const headers = this.getHeaders();
        return next.handle(req.clone({ headers })).pipe(
          catchError(error => this.handleError(error, req, next))
        );
      })
    );
  }

  handleTokenExpiration(): Observable<any> {
    const isExpired = this.cognitoService.isTokenExpired()
    if (isExpired) {
      const renewalObservable = this.cognitoService.refreshUserSession();
      return renewalObservable.pipe(
        switchMap(() => of(undefined)),
        catchError((err) => throwError(err))
      );
    }
    return of(undefined);
  }

  handleError(err: any, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (err instanceof HttpErrorResponse){
      if (err.status === 401 && !this.user?.uuid) {
        return this.cognitoService.refreshUserSession().pipe(
          switchMap(() => next.handle(req.clone({ headers: this.getHeaders() }))),
          catchError((error) => this.handleError(error, req, next))
        );
      }
      if(err.status === 401 && this.user?.origin === ExternalIntegration.KOBOSS){
        this.userService.cleanUserInfoStore();
        this.modalService.openSessionExpiredModal();
      }
      if (err.status === 400 && err.error?.message === 'order does not have initiated status. Cannot be modified') {
        return this.apiService.handleOrderError().pipe(
          switchMap(() => next.handle(req.clone({ headers: this.getHeaders(), body: { items: req.body.items }}))),
          catchError((error) => this.handleError(error, req, next))
        )
      }
      if (err.status === 409) {
        //TODO quitar este hideSpinner, showSpinner y hideSpinner deben estar solamente en el api.service
        this.spinnerService.hideSpinner(); 
        return this.handlePortfolioError().pipe(
          switchMap(() => next.handle(req.clone({ headers: this.getHeaders() }))),
          catchError((error) => this.handleError(error, req, next))
        );
      }
    }
    return throwError(err);
  }

  handlePortfolioError(): Observable<void>{
    return new Observable<void>((obs) => {
      this.userService.initClientSession().subscribe(() => {
        this.visitPlanService.getClientVisitPlan().subscribe({
          next: (res) => {
            this.store.dispatch(ClientActions.loadVisitDatesSuccess({ visitDates: res.data, visitDate: res.data[0].visitDate }));
            if (!(res.data.some(date => date.visitDate === new Date(this.cart.visitDate).toISOString()))) {
              this.store.dispatch(CartActions.updateDeliveryDate({ date: res.data[0] }));
            }
            this.visitPlanService.setOperationDate().subscribe(() => {
              obs.next();
            })
          },
          error: () => {
            this.store.dispatch(ClientActions.loadVisitDatesSuccess({ visitDates: [], visitDate: '' }));
          }
        });
      });
    });
  }

  getHeaders(): HttpHeaders {
    if (!this.user?.jwt && !this.userLocal?.organizationId) {
      return new HttpHeaders();
    }
    return new HttpHeaders({
      Authorization: this.user?.jwt ? `Bearer ${this.user.jwt}` : '',
      'X-B2B-Transaction-Id': uuidv4(),
      'Content-Type': 'application/json',
      'X-B2B-Organization-Id': this.user?.organizationId || this.userLocal?.organizationId || '',
      'X-App-Id': 'B2B',
      'X-App-Version': packageJson.version,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isAssetRequest(req: HttpRequest<any>): boolean {
    const APIS = [ environment.BASE_URL_PUBLIC, environment.BASE_URL_INTEGRATIONS, environment.BASE_URL_INTERNAL ];
    return !APIS.some(ext => req.url.includes(ext));
  }
}