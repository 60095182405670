import {
  configAR,
  configBR,
  configCL,
  configPY,
} from './qa/config-countries-qa';
import { IEnvironment } from './utils/env.model';
import { fiveMinutes, fourHours, windowLocation } from './utils/env.utils';

export const environment: IEnvironment = {
  production: false,
  type: 'QA',
  countriesConfig: {
    CL: configCL,
    AR: configAR,
    BR: configBR,
    PY: configPY,
  },
  BASE_URL_PUBLIC:
    'https://api.qa.miportalb2b.com/public/v1/public/api/cpg/001/country/',
  BASE_URL_INTERNAL:
    'https://api.qa.miportalb2b.com/internal/v1/internal/api/cpg/001/country/',
  BASE_URL_INTEGRATIONS:
    'https://api.qa.miportalb2b.com/integrations/v1/internal/api/cpg/001/country/',
  AWS_REGION: 'us-east-1',
  SIGN_IN_CALLBACK: windowLocation,
  SIGN_OUT_URL: windowLocation,
  AES_KEY: 'dSgVkYp2s5v8y/B?E(H+MbQeThWmZq4t6w9z$C&F)J@NcRfUjXn2r5u8x/A%D*G-',
  AES_KEY_MCC: '+xTUbNdy4C4$W@=v',
  WEB_DOMAIN: 'https://images.qa.miportalb2b.com/',
  GTM_ID: 'GTM-W6TG6DB',
  GTM_AUTH: 'pv2VvMD7C_E69A7zWJOfjQ',
  GTM_PREVIEW: 'env-737',
  ORDER_LIST_INIT_PATTERN: '1',
  DATA_SURVEY: 'V8qwDk',
  checkForNewVersionInterval: fiveMinutes,
  sessionTimeout: fourHours,
  enableServiceWorker: true,
  kobossWhatsapp: +5214429803006,
  clarityId: 'o7v7ndnfqg',
};
