<!-- DESKTOP -->
<div
  class="d-none d-md-block card bg-white px-3"
  [ngClass]="{ unavailable: unavailable }"
>
  <div class="suggested-label pt-3 pb-2" *ngIf="productSuggetedLabel">
    <p>
      <img src="../../../../assets/svg/sugerido.svg" alt="" />
      {{ ROOT + 'SUGGESTED_PRODUCT' | translate }}
    </p>
  </div>
  <app-ka-fav-button
    [product]="product"
    [isFavorite]="product?.isFavorite"
    class="fav-btn"
  ></app-ka-fav-button>
  <div class="row justify-content-center align-items-center">
    <div class="col-2">
      <div class="image w-100 m-0" (click)="cartService.openImage(product)">
        <img
          [ngSrc]="product.image | toThumbnail"
          alt=""
          (error)="onImgError()"
          fill
        />
      </div>
    </div>
    <div class="col-4 col-sm-5 col-xl-4">
      <div class="product-data bg-white">
        <span class="product-name">{{ product.name }} </span>
      </div>

      <div class="w-100 mb-3" *ngIf="product.relatedDiscount">
        <app-ka-status-pill
          [status]="pillMsg"
          [isDiscount]="true"
        ></app-ka-status-pill>
      </div>

      <p class="mid-strong mt-3" *ngIf="product.suggestedProduct?.isSuggested">
        {{ 'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_QUANTITY' | translate }}
        <span>{{ product.suggestedProduct.quantity }}</span>
        <span> {{ product.unitMeasure | lowercase }}</span>
        <span *ngIf="product.suggestedProduct.quantity > 1">s</span>
      </p>
    </div>
    <div class="col-2">
      <div class="product-data text-center bg-white">
        <div
          class="product-price w-100 text-center d-flex align-items-center justify-content-center flex-column p-0"
        >
          <span
            *ngIf="!(cart.discountProducts | kaDiscountsApplyProducts: product)"
            >{{ product.price.finalPrice | kaCurrency }}</span
          >

          <small
            *ngIf="product.price.listPrice > product.price.finalPrice"
            class="mb-2"
            >{{ product.price.listPrice | kaCurrency }}</small
          >

          <span
            *ngIf="cart.discountProducts | kaDiscountsApplyProducts: product"
            >{{
              cart.discountProducts
                | kaDiscountsApplyProducts: product
                | kaCurrency
            }}</span
          >
          <div>
            <small class="precio-unit">
              {{ ROOT_SCALE + 'PER_UNIT' | translate }}
            </small>
            <small class="precio-unit-bold">
              {{ product.price.priceBySubUnit | kaCurrency }}
            </small>
          </div>
        </div>

        <div
          class="open-modal-product-scale w-100 d-flex flex-row align-items-center justify-content-center mt-3"
          (click)="handleProductScale(product)"
          *ngIf="product.relatedDiscount"
        >
          <span>{{
            'NEW_ORDER.SUGGESTED_PRODUCTS.SCALE_BUTTON_MODAL' | translate
          }}</span>
          <svg>
            <use
              xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="col-4 col-sm-3 col-xl-4" *ngIf="page !== 'home'">
      <div class="d-flex flex-column my-xl-0 align-items-center">
        <app-ka-subunits
          [product]="product"
          *ngIf="client.subUnitAvailable && page === ProductTypes.UNIT"
          (subUnitChanged)="updateSubUnitQuantity($event)"
        ></app-ka-subunits>
        <app-ka-add-to-cart-button
          [product]="product"
          [subUnit]="subUnitSelected"
          [simpleProduct]="true"
        ></app-ka-add-to-cart-button>
        <span
          class="disclaimer-product-text mt-3"
          *ngIf="enableProductPriceDisclaimer"
          >{{ ROOT_LANG + 'PRODUCT_PRICE_DISCLAIMER' | translate }}</span
        >
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div
  class="d-md-none card bg-white p-3"
  [ngClass]="{ unavailable: unavailable }"
>
  <div class="suggested-label pt-3 pb-2" *ngIf="productSuggetedLabel">
    <p>
      <img src="../../../../assets/svg/sugerido.svg" alt="" />
      {{ ROOT + 'SUGGESTED_PRODUCT' | translate }}
    </p>
  </div>
  <app-ka-fav-button
    [product]="product"
    [isFavorite]="product?.isFavorite"
    class="fav-btn"
  ></app-ka-fav-button>
  <div class="row justify-content-center align-items-center m-0 p-0">
    <div class="d-none d-sm-flex col-sm-4">
      <div class="image" (click)="cartService.openImage(product)">
        <img
          [ngSrc]="product.image | toThumbnail"
          alt=""
          (error)="onImgError()"
          fill
        />
      </div>
    </div>
    <div class="p-0 col-12 col-sm-8">
      <div class="d-flex flex-row align-items-center justify-content-between">
        <div class="d-flex d-sm-none">
          <div class="image mr-8" (click)="cartService.openImage(product)">
            <img
              [ngSrc]="product.image | toThumbnail"
              alt=""
              (error)="onImgError()"
              fill
            />
          </div>
        </div>
        <div>
          <div class="product-data bg-white mb-3">
            <span class="product-name">{{ product.name }} </span>
          </div>
          <div class="w-100 mb-3" *ngIf="product.relatedDiscount">
            <app-ka-status-pill
              [status]="pillMsg"
              [isDiscount]="true"
            ></app-ka-status-pill>
          </div>

          <div class="product-data bg-white mb-md-2">
            <div
              class="d-flex flex-row align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex flex-column align-items-start justify-content-start"
              >
                <div
                  class="product-price d-flex flex-column align-items-start justify-content-start p-0 text-center"
                >
                  <span>{{ product.price.finalPrice | kaCurrency }}</span>
                  <small
                    *ngIf="product.price.listPrice > product.price.finalPrice"
                    class="mr-8"
                    >{{ product.price.listPrice | kaCurrency }}</small
                  >
                </div>
                <div>
                  <small class="precio-unit">
                    {{ ROOT_SCALE + 'PER_UNIT' | translate }}
                  </small>
                  <small class="precio-unit-bold">
                    {{ product.price.priceBySubUnit | kaCurrency }}
                  </small>
                </div>
                <span
                  class="disclaimer-product-text-mb"
                  *ngIf="enableProductPriceDisclaimer"
                  >{{
                    ROOT_LANG + 'PRODUCT_PRICE_DISCLAIMER' | translate
                  }}</span
                >
              </div>
            </div>
            <div
              class="open-modal-product-scale d-flex align-items-center"
              (click)="handleProductScale(product)"
              *ngIf="product.relatedDiscount"
            >
              <span>{{
                'NEW_ORDER.SUGGESTED_PRODUCTS.SCALE_BUTTON_MODAL' | translate
              }}</span>
              <svg>
                <use
                  xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
                />
              </svg>
            </div>
            <p
              class="mid-strong mt-2"
              *ngIf="product.suggestedProduct?.isSuggested"
            >
              {{
                'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_QUANTITY' | translate
              }}
              <span>{{ product.suggestedProduct.quantity }}</span>
              <span> {{ product.unitMeasure | lowercase }}</span>
              <span *ngIf="product.suggestedProduct.quantity > 1">s</span>
            </p>
          </div>
        </div>
      </div>
      <div class="pt-3" *ngIf="page !== 'home'">
        <app-ka-subunits
          [product]="product"
          *ngIf="client.subUnitAvailable && page === ProductTypes.UNIT"
          (subUnitChanged)="updateSubUnitQuantity($event)"
        ></app-ka-subunits>
        <app-ka-add-to-cart-button
          [product]="product"
          [subUnit]="subUnitSelected"
          [simpleProduct]="true"
        ></app-ka-add-to-cart-button>
      </div>
    </div>
  </div>
</div>
