import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize, from, Observable, shareReplay, Subscription, tap, timer, throwError } from 'rxjs';
import * as UserActions from 'src/app/core/state/actions/user.actions';
import {
  OtherDeviceLoginWarningComponent
} from 'src/app/shared/modals/other-device-login-warning/other-device-login-warning.component';
import {
  SessionExpiredModalComponent
} from 'src/app/shared/modals/session-expired-modal/session-expired-modal.component';
import { environment } from 'src/environments/environment';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { CognitoErrorResp } from '../models/backend/cognito-error-resp';
import { UserInfo } from '../models/user-info.model';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { 
  CognitoIdentityProviderClient, 
  ConfirmSignUpCommand, 
  ForgotPasswordCommand, 
  ResendConfirmationCodeCommand 
} from '@aws-sdk/client-cognito-identity-provider';
import { UserLocal } from '../models/user-local.model';
import { env } from 'src/app/app.component';
import { TokenResponse, TokenPayload } from 'src/app/core/models/auth-config.model';


@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  readonly EndpointsCodes = EndpointsCodes;
  cognitoClient: CognitoIdentityProviderClient;
  subscriptions = new Subscription();
  user: UserInfo;
  userPoolInstance: CognitoUserPool;
  isMCCAR = false;
  refreshTokenResponse$?: Observable<string>;
  private resetTimerRefreshToken?: Subscription;

  constructor(
    private spinner: NgxSpinnerService,
    private gtmService: GoogleTagManagerService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private store: Store<{ user: UserInfo; userLocal: UserLocal }>,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      }),
    );
    this.cognitoClient = new CognitoIdentityProviderClient({ region: environment.AWS_REGION });
  }

  refreshUserPoolInstance(): void {
    const awsClientId = this.isMCCAR ? env.getConfigByCountry()?.awsClientIdMCC : env.getConfigByCountry()?.awsClientId;
    const poolData = {
      IdentityPoolId: env.getConfigByCountry()?.identityPoolId,
      Region: environment.AWS_REGION,
      UserPoolId: env.getConfigByCountry()?.awsUserPoolId,
      ClientId: awsClientId,
    };
    this.userPoolInstance = new CognitoUserPool(poolData);
  }

  signInAws(username: string, password: string, event?: string) {
    this.spinner.show();

    const authenticationData = {
      Username: username,
      Password: password,
    };

    const userData = {
      Username: username,
      Pool: this.userPoolInstance
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType(env.getConfigByCountry()?.awsAuthFlow);

    return new Observable((obs) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          this.spinner.hide();
          this.saveCredentialsInBrowser(username, password);
          this.handleLoginSuccess(false, event).subscribe();
        },
        onFailure: (err) => {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_LOGIN, err);
          obs.error(err)
        },
        newPasswordRequired: (userAttributes) => {
          this.spinner.hide();
          obs.error({ message: 'NEW_PASSWORD_REQUIRED', email: userAttributes.email })
        },
      });
    });
  }

  handleLoginSuccess(isSocial = false, event?: string): Observable<void> {
    this.store.dispatch(UserActions.isSocial({ isSocial }));
    this.spinner.show();
    return new Observable<void>((obs) => {
      const cognitoUser = this.userPoolInstance.getCurrentUser();
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_LOGIN, err);
          obs.error(err);
        } else {
          this.spinner.hide();
          const idToken = session.getIdToken().getJwtToken();
          const decodedIdToken = jwt_decode(idToken);
          const username = decodedIdToken['cognito:username'] || decodedIdToken['username'];
          const refreshToken = session.getRefreshToken().getToken();

          this.store.dispatch(UserActions.loadJwt({ jwt: idToken }));
          this.store.dispatch(UserActions.loadRefreshJwt({ refreshJwt: refreshToken }));
          this.store.dispatch(UserActions.loadCognitoUserName({ cognitoUserName: username }));
          this.store.dispatch(UserActions.loginUser({ cognitoUsername: username, loginEvent: event, isSocial }));

          obs.next();
        }
      });
    });
  }

  private saveCredentialsInBrowser(username, password): boolean {
    if (!(window as any).PasswordCredential) {
      return false;
    }
    const cred = new (window as any).PasswordCredential({
      id: username,
      password,
      name: username,
    });
    navigator.credentials.store(cred);
  }

  isTokenExpired(): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = this.user.jwt ? jwt_decode(this.user.jwt)['exp'] : undefined;
    return expirationTime - currentTime < 60;
  }

  signInMCC(userSession) {
    this.isMCCAR = true;
    this.refreshUserPoolInstance();
    this.setUserSession({
      id_token: userSession.IdToken,
      access_token: userSession.AccessToken,
      refresh_token: userSession.RefreshToken,
    });
  }

  setUserSession(tokens: TokenResponse): void {
    const decoded = jwt_decode<TokenPayload>(tokens.id_token);
    const userEmail = decoded.email;

    const cognitoUser = new CognitoUser({
      Username: userEmail,
      Pool: this.userPoolInstance
    });

    // ClockDrift is the difference between the client and the server time when generating the tokens
    // since we are creating the session manually we can set it to 0, because there is no server involved
    const sessionData = {
      ClockDrift: 0,
      IdToken: new CognitoIdToken({ IdToken: tokens.id_token }),
      AccessToken: new CognitoAccessToken({ AccessToken: tokens.access_token }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refresh_token }),
    };

    const cognitoSession = new CognitoUserSession(sessionData);

    cognitoUser.setSignInUserSession(cognitoSession);
  }

  refreshUserSession(): Observable<string> {
    this.refreshTokenResponse$ ||= new Observable<string>((obs) => {
      const cognitoUser = this.userPoolInstance.getCurrentUser();
      
      if (cognitoUser === null) {
        this.forceLogoutUser();
        obs.error('Cognito: Can not get the session: null user');
        return;
      }
      
      // getSession automatically refresh the session if needed. It includes a retry mechanism inside
      cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          this.forceLogoutUser();
          obs.error(`Cognito: Can not get or refresh session: ${err}`);
          return;
        }

        this.store.dispatch(UserActions.loadJwt({ jwt: session.getIdToken().getJwtToken() }));
        obs.next('Cognito: refreshed successfully');
        obs.complete();
      });
    }).pipe(
      shareReplay(1),
      finalize(() => {
        // save results for 5 seconds to prevent repeated calls to refresh token api
        // we don't use shareReplay with refCount because in case of error it fails too fast
        this.resetTimerRefreshToken?.unsubscribe();
        this.resetTimerRefreshToken = timer(5000).subscribe(() => {
          this.refreshTokenResponse$ = null;
          this.resetTimerRefreshToken = undefined;
        });
      })
    );

    return this.refreshTokenResponse$;
  }

  forceLogoutUser(): any {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    const logoutModal = this.isMCCAR ? OtherDeviceLoginWarningComponent : SessionExpiredModalComponent;
    this.modalService.open(logoutModal, { windowClass: 'ngbmodal-centered' })
  }
 
  confirmSignUpAws(username: string, code: string): Observable<any> {
    this.spinner.show();

    const command = new ConfirmSignUpCommand({
      ClientId: env.getConfigByCountry()?.awsClientId,
      Username: username,
      ConfirmationCode: code,
    });

    return from(this.cognitoClient.send(command)).pipe(
      finalize(() => this.spinner.hide()),
      catchError(error => {
        this.pushGTMError(EndpointsCodes.AWS_CONFIRM_SIGN_UP, error);
        return throwError(error);
      })
    );
  }

  forgotPassword(username: string, metadata: { cpgId: string, countryId: string, organizationId: string }): Observable<any> {
  this.spinner.show();
  
    const command = new ForgotPasswordCommand({
      ClientId: env.getConfigByCountry()?.awsClientId,
      Username: username,
      ClientMetadata: metadata,
    });
    
    return from(this.cognitoClient.send(command)).pipe(
      tap(() => this.spinner.hide()),
      catchError(error => {
        this.spinner.hide();
        this.pushGTMError(EndpointsCodes.AWS_SEND_CONFIRM_CODE, error);
        return throwError(error);
      })
    );
  }

  resendSignUp(username: string, metadata: { cpgId: string, countryId: string, organizationId: string }): Observable<any> {
    this.spinner.show();
      const command = new ResendConfirmationCodeCommand({
        ClientId: env.getConfigByCountry()?.awsClientId,
        Username: username,
        ClientMetadata: metadata,
      });

      return from(this.cognitoClient.send(command)).pipe(
      tap(() => this.spinner.hide()),
      catchError(error => {
        this.spinner.hide();
        this.pushGTMError(EndpointsCodes.AWS_RESEND_SIGN_UP, error);
        return throwError(error);
      })
    );
  }  

  signOut(): Observable<void> {
    const cognitoUser = this.userPoolInstance.getCurrentUser();
    this.spinner.show();
    return new Observable<void>((obs) => {
      this.spinner.hide();
      cognitoUser.signOut();
      obs.next();
      obs.complete();
    });
  }

  pushGTMError(serviceKey, cognitoError: CognitoErrorResp): void {
    let description;
    this.translateService.get('ERRORS.' + cognitoError.code).subscribe((errorText: string) => {
      description = errorText.startsWith('ERRORS.') ? cognitoError.name + ' - ' + cognitoError.message : errorText;
    });

    this.gtmService.pushTag({
      event: 'error',
      error: {
        serviceKey,
        source: 'cognito',
        description,
      },
    });
  }

}
