import { createAction, props } from '@ngrx/store';
import { Discount } from '../../models/discount.model';
import { Product } from '../../models/product.model';
import { BERespErrorModel } from '../../models/backend/BE-response-error.model';
import { ProductFilter } from '../../models/product-filter.model';
import { PortfolioFilters } from '../../models/portfolio-filters.model';

export const loadPortfolio = createAction(
  '[Portfolio] loadPortfolio',
  props<{
    offset?: number;
  }>(),
);

export const loadPortfolioSuccess = createAction(
  '[Portfolio] loadPortfolioSuccess',
  props<{
    products: (Product | Discount)[];
    page: number;
    totalProducts: number;
  }>(),
);

export const loadPortfolioError = createAction(
  '[Portfolio] loadPortfolioError',
  props<{ error: BERespErrorModel }>(),
);

export const loadPortfolioFilter = createAction(
  '[Portfolio] loadPortfolioFilter',
  props<{ productFilters: ProductFilter | null }>(),
);

export const updatePortfolioFilter = createAction(
  '[Portfolio] updatePortfolioFilter',
  props<{ productFilters: ProductFilter }>(),
);

export const cleanProductFilters = createAction(
  '[Portfolio] cleanProductFilters',
);

export const loadPortfolioFilterSuccess = createAction(
  '[Portfolio] loadPortfolioFilterSuccess',
  props<{ portfolioFilters: PortfolioFilters }>(),
);

export const loadPortfolioFilterError = createAction(
  '[Portfolio] loadPortfolioFilterError',
  props<{ error: BERespErrorModel }>(),
);

export const refreshPortfolio = createAction('[Portfolio] refreshPortfolio');

export const getDataPortfolio = createAction(
  '[Portfolio] Get discount, suggested products',
  props<{
    productFilters: ProductFilter;
    offset?: number;
  }>(),
);

export const getDataPortfolioSuccess = createAction(
  '[Portfolio] Get discount, suggested products Success',
  props<{
    products: (Product | Discount)[];
    page: number;
    totalProducts: number;
  }>(),
);

export const getDataPortfolioFailure = createAction(
  '[Portfolio] Get discount, suggested products Failure',
  props<{ error: BERespErrorModel }>(),
);
