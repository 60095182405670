import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Client } from '../models/client.model';
import { UserInfo } from '../models/user-info.model';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { BERespModel } from '../models/backend/BE-response.model';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private subscriptions = new Subscription();
  selectedClient: Client;
  user: UserInfo;

  constructor(
    private store: Store<{ client: Client, user: UserInfo }>, 
    private apiSrv: ApiService,
    private toastr: ToastrService,
    private translateService: TranslateService) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
      })
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      })
    );
  }

  getBannerImages(clientId: number): Observable<BERespModel> {
    return new Observable((obs) => {
      this.apiSrv.get(`clients/${clientId}/banners?sourceChannel=B2B`, EndpointsCodes.GET_BANNERS, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => {
          const errorMessage = this.translateService.instant('HOME.BANNERS.ERROR_LOAD_BANNER');
          this.toastr.error(errorMessage);
          obs.error(err);
        },
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}