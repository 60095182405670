import { createReducer, on, props } from '@ngrx/store';

import { Portfolio } from '../../models/portfolio.model';

import * as PortfolioActions from '../actions/portfolio.actions';

export const INITIAL_PRODUCT_FILTERS = {
  category: '',
  package: '',
  brand: '',
  size: '',
  returnability: null,
  text: '',
  isDiscount: null,
};

const initialState: Portfolio = {
  productsAndDiscountsList: [],
  primaryFilter: '',
  secondaryFilter: '',
  viewGrid: true,
  totalProducts: 0,
  page: 1,
  isSuggested: false,
  error: null,
  portfolioFilters: null,
  productFilters: INITIAL_PRODUCT_FILTERS,
};

export const portfolioReducer = createReducer<Portfolio>(
  initialState,
  on(PortfolioActions.loadPortfolio, (state): Portfolio => ({ ...state })),
  on(
    PortfolioActions.loadPortfolioSuccess,
    (state, props): Portfolio => ({
      ...state,
      productsAndDiscountsList: props.products,
      page: props.page,
      totalProducts: props.totalProducts,
    }),
  ),
  on(
    PortfolioActions.loadPortfolioFilter,
    (state): Portfolio => ({
      ...state,
    }),
  ),
  on(
    PortfolioActions.updatePortfolioFilter,
    (state, prop): Portfolio => ({
      ...state,
      productFilters: prop.productFilters,
    }),
  ),
  on(
    PortfolioActions.loadPortfolioFilterSuccess,
    (state, props): Portfolio => ({
      ...state,
      portfolioFilters: props.portfolioFilters,
    }),
  ),
  on(
    PortfolioActions.cleanProductFilters,
    (state): Portfolio => ({
      ...state,
      productFilters: INITIAL_PRODUCT_FILTERS,
    }),
  ),
  on(
    PortfolioActions.getDataPortfolio,
    (state, { offset, productFilters }): Portfolio => ({
      ...state,
      productFilters,
      offset,
    }),
  ),
  on(
    PortfolioActions.getDataPortfolioSuccess,
    (state, { products, page, totalProducts }): Portfolio => ({
      ...state,
      productsAndDiscountsList: products,
      page,
      totalProducts,
    }),
  ),
  on(
    PortfolioActions.getDataPortfolioFailure,
    (state, { error }): Portfolio => ({ ...state, error }),
  ),
);
