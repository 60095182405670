import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/core/models/cart.model';
import { ProductFilter } from 'src/app/core/models/product-filter.model';
import * as PortfolioActions from 'src/app/core/state/actions/portfolio.actions';

export type sortByOptions = 'expensive' | 'cheaper' | 'AZ' | 'ZA' | 'RELEVANT';

@Component({
  selector: 'app-ka-order-filter-dropdown',
  templateUrl: './ka-order-filter-dropdown.component.html',
  styleUrls: ['./ka-order-filter-dropdown.component.scss'],
})
export class KaOrderFilterDropdownComponent {
  @Input() productFilter: ProductFilter;

  readonly ROOT = 'NEW_ORDER.PRODUCTS.FILTER.ORDER';
  orderSort = 'RELEVANT';

  constructor(private store: Store<{ cart: Cart }>) {}

  updateSortFilter(option: sortByOptions): void {
    this.orderSort  =option.toUpperCase();
    const productFilters: ProductFilter = {
      ...this.productFilter,
      sortBy: option === 'RELEVANT' ? null : option,
    };
    this.updateProductFilterState(productFilters);
  }

  private updateProductFilterState(productFilters: ProductFilter): void {
    this.store.dispatch(
      PortfolioActions.updatePortfolioFilter({
        productFilters: { ...productFilters },
      }),
    );
  }
}


