import {
  createReducer,
  on,
} from '@ngrx/store';
import { Cart } from '../../models/cart.model';
import { Product } from '../../models/product.model';
import * as CartActions from '../actions/cart.actions';
import { ProductsCalcs } from '../../utils/products-calcs';


const initialState: Cart = {
  visitDate: '',
  invoiceDeadline: '',
  isFirstDeliveryDate: false,
  backupProducts: [],
  products: [],
  discountProducts: [],
  orderConfirmed: false,
  paymentMethod: {},
  credits: [],
  isRepeated: false,
  hasTermsConditionsAccepted: null,
  hasOrderError: false,
  totalDiscountsByCategory: [],
  multiplePaymentMethodsBySegment: [],
  fridayMessage: {},
  minOrderAmbient: {
    value: 0,
    isOffRoute: false,
    isByQuantity: false,
    isFrozen: false,
  },
  minOrderFrozen: {
    value: 0,
    isOffRoute: false,
    isByQuantity: false,
    isFrozen: true,
  } 
};

export const cartReducer = createReducer<Cart>(
  initialState,
  on(CartActions.upsertProduct, (state, props): Cart => {
    const newProdArray = handleUpsertProducts(state, [props.product]);
    return { ...state, products: newProdArray };
  }),
  on(CartActions.upsertMultipleProducts, (state, props): Cart => {
    const newProdArray = handleUpsertProducts(state, props.products);
    return { ...state, products: newProdArray };
  }),
  on(CartActions.deleteProduct, (state, props): Cart => {
    const { products } = state;

    const newProdArray: Product[] = products.filter((prod) => {
      return prod.subunitSelected
        ? prod.productId !== props.product.productId ||
            (prod.productId === props.product.productId &&
              prod.subunitSelected !== props.product.erpMeasureUnitId)
        : prod.productId !== props.product.productId;
    });

    return {
      ...state,
      products: newProdArray,
    };
  }),
  on(CartActions.deleteAnyProduct, (state, props): Cart => {
    const { products, discountProducts } = state;

    const newProducts: Product[] = products.filter(
      (prod) => prod.productId !== props.product.productId,
    );
    const newDiscountProducts: Product[] = discountProducts.filter(
      (prod) => prod.productId !== props.product.productId,
    );
    return {
      ...state,
      products: newProducts,
      discountProducts: newDiscountProducts,
    };
  }),
  on(CartActions.bulkDeleteProducts, (state, props): Cart => {
    const { products } = state;

    const newProdArray: Product[] = products.filter(
      (prod) =>
        !props.products.some((p) => {
          if (prod.productId === p.productId) {
            return true;
          }

          return (
            prod.subunitSelected && prod.subunitSelected !== p.erpMeasureUnitId
          );
        }),
    );

    return {
      ...state,
      products: newProdArray,
    };
  }),
  on(CartActions.bulkDeleteAnyProducts, (state, props): Cart => {
    const { products, discountProducts } = state;

    const newProducts = products.filter(
      (prod) => !props.products.some((p) => prod.productId === p.productId),
    );
    const newDiscountProducts = discountProducts.filter(
      (prod) => !props.products.some((p) => prod.productId === p.productId),
    );

    return {
      ...state,
      products: newProducts,
      discountProducts: newDiscountProducts,
    };
  }),
  on(CartActions.deleteAllProductsFrozen, (state): Cart => {
    const products = state.products.filter(
      (prod) => prod.deliveryType !== 'deliveryfrozen',
    );
    return { ...state, products };
  }),
  on(CartActions.deleteAllProductsNotFrozen, (state): Cart => {
    let products = state.products.filter(
      (prod) => prod.deliveryType !== 'delivery',
    );
    products = products.filter((prod) => prod.deliveryType !== undefined);
    return { ...state, products };
  }),
  on(
    CartActions.deleteAllProducts,
    (state): Cart => ({ ...state, backupProducts: [], products: [] }),
  ),
  on(
    CartActions.updateAllProducts,
    (state, prop): Cart => ({ ...state, products: prop.products }),
  ),
  on(
    CartActions.loadProductDiscountsSuccess,
    (state, prop): Cart => updateCartValues(state, prop.data),
  ),
  on(CartActions.loadProductDiscountsError, (state): any => ({
    ...state,
    products: state.backupProducts,
  })),
  on(
    CartActions.updateOffRoute,
    (state, prop): Cart => ({ ...state, offRoute: prop.offRoute }),
  ),
  on(
    CartActions.updateDeliveryDate,
    (state, date): Cart => ({ ...state, visitDate: date.date.visitDate }),
  ),
  on(
    CartActions.updateDeliveryDateSuccess,
    (state, date): Cart => ({ ...state, visitDate: date.date }),
  ),
  on(
    CartActions.updateDeliveryDateError,
    (state): Cart => ({ ...state, visitDate: '' }),
  ),

  on(CartActions.loadDeliveryDates, (state): Cart => ({ ...state })),
  on(CartActions.loadDeliveryDatesSuccess, (state): Cart => ({ ...state })),
  on(CartActions.loadDeliveryDatesError, (state): Cart => ({ ...state })),

  on(CartActions.loadFrozenDeliveryDates, (state): Cart => ({ ...state })),
  on(
    CartActions.loadFrozenDeliveryDatesSuccess,
    (state): Cart => ({ ...state }),
  ),
  on(CartActions.loadFrozenDeliveryDatesError, (state): Cart => ({ ...state })),
  on(CartActions.deliveryFrozenActions, (state, props): Cart => ({ ...state })),
  on(CartActions.deliveryFrozenActionsSuccess, (state): Cart => ({ ...state })),
  on(CartActions.deliveryFrozenActionsError, (state): Cart => ({ ...state })),

  on(CartActions.setFrozenOperationDate, (state): Cart => ({ ...state })),
  on(
    CartActions.setFrozenOperationDateSuccess,
    (state): Cart => ({ ...state }),
  ),
  on(CartActions.setFrozenOperationDateError, (state): Cart => ({ ...state })),

  on(
    CartActions.setFrozenOperationDateAndAddProduct,
    (state): Cart => ({ ...state }),
  ),
  on(
    CartActions.setFrozenOperationDateAndAddProductSuccess,
    (state): Cart => ({ ...state }),
  ),
  on(
    CartActions.setFrozenOperationDateAndAddProductError,
    (state): Cart => ({ ...state }),
  ),

  on(
    CartActions.updateRepeated,
    (state, prop): Cart => ({ ...state, isRepeated: prop.isRepeated }),
  ),
  on(
    CartActions.updateFrozenVisitDate,
    (state, date): Cart => ({ ...state, frozenVisitDate: date.date }),
  ),
  on(
    CartActions.updateInvoiceDeadline,
    (state, prop): Cart => ({
      ...state,
      invoiceDeadline: prop.invoiceDeadline,
    }),
  ),
  on(CartActions.confirmOrder, (state): Cart => ({ ...state })),
  on(
    CartActions.confirmOrderSuccess,
    (state): Cart => ({ ...state, orderConfirmed: true }),
  ),
  on(
    CartActions.confirmOrderError,
    (state): Cart => ({ ...state, orderConfirmed: false }),
  ),
  on(
    CartActions.cleanCart,
    (state): Cart => ({
      ...initialState,
      paymentMethod: state.paymentMethod,
      visitDate: state.visitDate,
      invoiceDeadline: state.invoiceDeadline,
      minOrderAmbient: state.minOrderAmbient,
      minOrderFrozen: state.minOrderFrozen,
      offRoute: state.offRoute,
      multiplePaymentMethodsBySegment: state.multiplePaymentMethodsBySegment
    }),
  ),
  on(CartActions.updateHasDeliveryFrozenProducts, (state): Cart => {
    return { ...state };
  }),
  on(CartActions.updateHasDeliveryNotFrozenProducts, (state): Cart => {
    return { ...state };
  }),
  on(
    CartActions.updateConfirmDeleteProduct,
    (state, props): Cart => ({
      ...state,
      products: handleProductsConfirmDelete(state, props.product),
    }),
  ),
  on(
    CartActions.upsertpaymentMethod,
    (state, prop): Cart => ({ ...state, paymentMethod: prop.paymentMethod }),
  ),
  on(
    CartActions.updateCartCredits,
    (state, prop): Cart => ({ ...state, credits: prop.credits }),
  ),
  on(
    CartActions.removeOrderId,
    (state): Cart => ({ ...state, orderId: undefined }),
  ),
  on(
    CartActions.upsertPurchaseOrderNumber,
    (state, prop): Cart => ({
      ...state,
      purchaseOrderNumber: prop.purchaseOrderNumber,
    }),
  ),
  on(
    CartActions.hasTermsConditionsAccepted,
    (state, prop): Cart => ({
      ...state,
      hasTermsConditionsAccepted: prop.hasTermsConditionsAccepted,
    }),
  ),
  on(
    CartActions.hasOrderError,
    (state, prop): Cart => ({ ...state, hasOrderError: prop.hasOrderError }),
  ),
  on(
    CartActions.hasPerceptionAlertOpen,
    (state, prop): Cart => ({
      ...state,
      hasPerceptionAlertOpen: prop.hasPerceptionAlertOpen,
    }),
  ),
  on(
    CartActions.haslockedCategoryAlertOpen,
    (state, prop): Cart => ({
      ...state,
      haslockedCategoryAlertOpen: prop.haslockedCategoryAlertOpen,
    }),
  ),
  on(
    CartActions.refreshOrderOnUpdateDate,
    (state, prop): Cart => ({ ...state }),
  ),
  on(CartActions.addFavoriteProduct, (state): Cart => ({ ...state })),
  on(CartActions.addFavoriteProductSuccess, (state): Cart => ({ ...state })),
  on(CartActions.addFavoriteProductError, (state): Cart => ({ ...state })),
  on(CartActions.removeFavoriteProduct, (state): Cart => ({ ...state })),
  on(CartActions.removeFavoriteProductSuccess, (state): Cart => ({ ...state })),
  on(CartActions.removeFavoriteProductError, (state): Cart => ({ ...state })),
  on(
    CartActions.cleanCartWithoutFilters,
    (state): Cart => ({
      ...initialState,
      paymentMethod: state.paymentMethod,
      visitDate: state.visitDate,
      invoiceDeadline: state.invoiceDeadline,
      offRoute: state.offRoute,
      minOrderAmbient: state.minOrderAmbient,
      minOrderFrozen: state.minOrderFrozen,
      // productFilters: state.productFilters,
    }),
  ),
  on(
    CartActions.updateMultiplePaymentMethod,
    (state, prop): Cart => ({
      ...state,
      multiplePaymentMethodsBySegment: prop.multiplePaymentMethodsBySegment,
    }),
  ),
  on(CartActions.updateFridayMessage, (state, { fridayMessage }): Cart => ({
    ...state,
    fridayMessage,
  })),
  on(CartActions.updateMinOrderAmbient, (state, { minOrder }): Cart => ({
    ...state,
    minOrderAmbient: { ...minOrder },
  })),
  on(CartActions.updateMinOrderFrozen, (state, { minOrder }): Cart => ({
    ...state,
    minOrderFrozen: { ...minOrder },
  })),
  on(CartActions.updateFrozenDeliveryDate, (state, { date }): Cart => ({
    ...state,
    frozenVisitDate: date.visitDate
  })),
  on(CartActions.setInitialDeliveryDate, (state, { date }): Cart => ({
    ...state,
    visitDate: date.visitDate
  })),
);

const handleUpsertProducts = (state, products: Product[]) => {
  let newProdArray = [...state.products];
  products.forEach((product) => {
    const isAlreadyInCart = product.enabledToSellBySubUnit
      ? newProdArray.some(
          (prod) =>
            prod.productId === product.productId &&
            (prod.subunitSelected ===
              (product.subunitSelected || product.erpMeasureUnitId) ||
              !prod.subunitSelected),
        )
      : newProdArray.some((prod) => prod.productId === product.productId);

    if (isAlreadyInCart) {
      newProdArray = newProdArray.map((prod) => {
        let quantity;
        if (
          prod.enabledToSellBySubUnit &&
          (product.subunitSelected || product.erpMeasureUnitId)
        ) {
          quantity =
            prod.productId === product.productId &&
            (prod.subunitSelected ===
              (product.subunitSelected || product.erpMeasureUnitId) ||
              !prod.subunitSelected)
              ? product.quantitySelected + prod.quantity
              : prod.quantity;
        } else {
          quantity =
            prod.productId === product.productId
              ? product.quantitySelected + prod.quantity
              : prod.quantity;
        }
        return { ...prod, quantity };
      });
    } else {
      newProdArray = [...newProdArray, parseProduct(product)];
    }
  });
  return newProdArray;
};

// parseProduct before createParcialOrder
const parseProduct = (product: Product, quantity?: number) => {
  return {
    productId: product.productId,
    quantity: quantity | product.quantitySelected,
    name: product.name,
    productGroupName: product.productGroupName,
    image: product.image,
    price: {
      listPrice: parseFloat(product.price?.listPrice as any) | 0,
      finalPrice: parseFloat(product.price?.finalPrice as any) | 0,
      shippingPrice: parseFloat(product.price?.shippingPrice as any) | 0,
      taxes: parseFloat(product.price?.taxes as any) | 0,
      others: parseFloat(product.price?.others as any) | 0,
      discounts: parseFloat(product.price?.discounts as any) | 0,
      priceBySubUnit: parseFloat(product.price?.priceBySubUnit as any) | 0,
    },
    portfolioPriceId:
      product?.portfolioPriceId | product?.price?.portfolioPriceId,
    suggestedProduct: product.suggestedProduct,
    deliveryType: product.deliveryType,
    subunitSelected: product?.subunitSelected || product?.erpMeasureUnitId,
    enabledToSellBySubUnit: product?.enabledToSellBySubUnit
      ? product?.enabledToSellBySubUnit
      : false,
    subUnitInfo: product?.subUnit,
    size: product.size,
    package: product.package,
    erpMeasureUnitId: product.erpUnitMeasureId || product.erpMeasureUnitId,
    isBom: product?.isBom,
    segment: product?.segment,
    isDraft: product?.isDraft,
  };
};

const _updateProductQuantityAgainstCart = (
  products: Product[],
  cartProducts: Product[],
) => {
  // sincronizamos las cantidades seleccionadas con las que devuelve el back
  // el back puede devolver productos de menos porque se supero el maximo permitido o porque el producto ya no existe
  return products
    .map((product) => {
      const matchingProduct = product.subunitSelected
        ? cartProducts.find(
            (cartItem) =>
              product.productId === cartItem.productId &&
              product.subunitSelected === cartItem.erpMeasureUnitId,
          )
        : cartProducts.find(
            (cartItem) => product.productId === cartItem.productId,
          );
      const price = matchingProduct
        ? {
            ...matchingProduct.price,
            listPrice: ProductsCalcs.getItemFullListPrice(matchingProduct),
          }
        : product.price;
      return {
        ...product,
        price,
        quantity: matchingProduct?.quantity ?? product.quantity,
      };
    })
    .filter((product) => product.quantity > 0);
};

//updates cart state after createParcialOrder
const updateCartValues = (state: Cart, data) => {
  const products = _updateProductQuantityAgainstCart(state.products, data.calculatedItems);
  return {
    ...state,
    products,
    backupProducts: products,
    discountProducts: [...data.calculatedItems]?.reverse(),
    orderId: data.orderId,
    enabledToConfirmOrder: data.enabledToConfirmOrder,
    totalDiscountsByCategory: data.totalDiscountsByCategory,
    fridayMessage: data.fridayMessage,
  };
};

const handleProductsConfirmDelete = (state: Cart, product: Product) => {
  const { products } = state;

  return products.map((prod) => {
    if (prod?.isBom && prod.productId === product.productId) {
      return { ...prod, confirmDelete: prod.confirmDelete ? false : true };
    }

    const confirm =
      prod.enabledToSellBySubUnit &&
      (product.subunitSelected || product.erpMeasureUnitId)
        ? prod.productId === product.productId &&
          (prod.subunitSelected ===
            (product.subunitSelected || product.erpMeasureUnitId) ||
            !prod.subunitSelected)
          ? !prod.confirmDelete
          : prod.confirmDelete
        : prod.productId === product.productId
          ? !prod.confirmDelete
          : prod.confirmDelete;

    return { ...prod, confirmDelete: confirm };
  });
};
